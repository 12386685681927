import type { HydratedDocument } from 'mongoose';
import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';
import type { UserDocument } from './user';

export enum EntityEventType {
  SOS = 'SOS',
  NO_MOVEMENT = 'NO_MOVEMENT',
  AWAY_FROM_TRACK = 'AWAY_FROM_TRACK',
  LOW_BATTERY = 'LOW_BATTERY',
  UNKNOWN = 'UNKNOWN',
  POWER_OFF = 'POWER_OFF',
  CRASH = 'CRASH',
  HEARTBEAT = 'HEARTBEAT',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  REBOOT = 'REBOOT',
  DEVICE_INFO = 'DEVICE_INFO',
  NONE = 'NONE',

  // Configuration events
  CONFIG_UPDATE_COMMAND_STARTING = 'CONFIG_UPDATE_COMMAND_STARTING',
  CONFIG_UPDATE_COMMAND_CONFIRMED = 'CONFIG_UPDATE_COMMAND_CONFIRMED',
  CONFIG_UPDATE_COMMAND_REFUSED = 'CONFIG_UPDATE_COMMAND_REFUSED',
  CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY = 'CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY',
  CONFIG_DOWNLOAD_STARTING = 'CONFIG_DOWNLOAD_STARTING',
  CONFIG_DOWNLOAD_SUCCESS = 'CONFIG_DOWNLOAD_SUCCESS',
  CONFIG_DOWNLOAD_FAILED = 'CONFIG_DOWNLOAD_FAILED',
  CONFIG_DEVICE_UPDATE_STARTING = 'CONFIG_DEVICE_UPDATE_STARTING',
  CONFIG_DEVICE_UPDATE_SUCCESS = 'CONFIG_DEVICE_UPDATE_SUCCESS',
  CONFIG_DEVICE_UPDATE_FAILED = 'CONFIG_DEVICE_UPDATE_FAILED',
}
export const EntityEventTypeSchema = z.nativeEnum(EntityEventType);

export enum EntityType {
  STARTERS = 'STARTERS',
  DEVICES = 'DEVICES',
  EVENTS = 'EVENTS',
}
export const EntityTypeSchema = z.nativeEnum(EntityType);

export enum EntityEventState {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  STANDBY = 'STANDBY',
  RESOLVED = 'RESOLVED',
}

export type EntityEventStateWithoutNew = Exclude<EntityEventState, EntityEventState.NEW>;

export type EntityEventTypeBasicAlert = Extract<
  EntityEventType,
  | EntityEventType.SOS
  | EntityEventType.NO_MOVEMENT
  | EntityEventType.AWAY_FROM_TRACK
  | EntityEventType.LOW_BATTERY
  | EntityEventType.CRASH
  | EntityEventType.POWER_OFF
>;

export const EntityEventStateSchema = z.nativeEnum(EntityEventState);

export const EntityEventStateWithoutNewSchema = EntityEventStateSchema.refine(
  (val) => val !== EntityEventState.NEW,
  {
    message: 'Value cannot be NEW',
  },
);

export const EntityEventTypeBasicAlertSchema = EntityEventTypeSchema.refine(
  (val) =>
    val === EntityEventType.SOS ||
    val === EntityEventType.NO_MOVEMENT ||
    val === EntityEventType.AWAY_FROM_TRACK ||
    val === EntityEventType.LOW_BATTERY ||
    val === EntityEventType.CRASH ||
    val === EntityEventType.POWER_OFF,
  {
    message: 'Value is not a basic alert event type',
  },
);

export const EntityEventStateLabels: Record<EntityEventState, string> = {
  [EntityEventState.NEW]: 'New',
  [EntityEventState.IN_PROGRESS]: 'In progress',
  [EntityEventState.STANDBY]: 'Standby',
  [EntityEventState.RESOLVED]: 'Resolved',
};

export const EntityEventLabels: Record<EntityEventType, string> = {
  [EntityEventType.SOS]: 'SOS',
  [EntityEventType.NO_MOVEMENT]: 'No movement',
  [EntityEventType.AWAY_FROM_TRACK]: 'Away from track',
  [EntityEventType.LOW_BATTERY]: 'Low battery',
  [EntityEventType.POWER_OFF]: 'Power off',
  [EntityEventType.UNKNOWN]: 'Unknown',
  [EntityEventType.CRASH]: 'Crash/Fall Accident',
  [EntityEventType.HEARTBEAT]: 'Heartbeat',
  [EntityEventType.OFFLINE]: 'Offline',
  [EntityEventType.ONLINE]: 'Online',
  [EntityEventType.REBOOT]: 'Reboot',
  [EntityEventType.DEVICE_INFO]: 'Device info',
  [EntityEventType.NONE]: 'None',
  [EntityEventType.CONFIG_UPDATE_COMMAND_STARTING]: 'Update command starting',
  [EntityEventType.CONFIG_UPDATE_COMMAND_CONFIRMED]: 'Update command confirmed',
  [EntityEventType.CONFIG_UPDATE_COMMAND_REFUSED]: 'Update command refused',
  [EntityEventType.CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY]:
    'Update process refused due to low battery',
  [EntityEventType.CONFIG_DOWNLOAD_STARTING]: 'Download starting',
  [EntityEventType.CONFIG_DOWNLOAD_SUCCESS]: 'Download success',
  [EntityEventType.CONFIG_DOWNLOAD_FAILED]: 'Download failed',
  [EntityEventType.CONFIG_DEVICE_UPDATE_STARTING]: 'Device update starting',
  [EntityEventType.CONFIG_DEVICE_UPDATE_SUCCESS]: 'Device update success',
  [EntityEventType.CONFIG_DEVICE_UPDATE_FAILED]: 'Device update failed',
};

export const EntityEventBaseSchema = z.object({
  entityType: EntityTypeSchema,
  eventType: EntityEventTypeSchema,
  state: EntityEventStateSchema,
  location: z
    .object({
      lat: z.number().optional(),
      lng: z.number().optional(),
    })
    .optional(),
  information: z.string().optional(),
});

export type EntityEventRaw = z.infer<typeof EntityEventBaseSchema>;

export const EntityEventSchema = EntityEventBaseSchema.extend({
  id: ObjectIdSchema,
  updaterId: ObjectIdSchema,
  occuredAt: DateSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  stateEditedAt: DateSchema.nullable().optional(),
  stateEditedBy: ObjectIdSchema.nullable().optional(),
  entityId: ObjectIdSchema,
  creatorId: ObjectIdSchema,
});

export type EntityEvent = z.infer<typeof EntityEventSchema>;

export const EntityEventsSchema = z.array(EntityEventSchema);

export type EntityEvents = z.infer<typeof EntityEventsSchema>;

export type EntityEventDocument = Omit<
  HydratedDocument<EntityEvent, EntityEventDocumentMethods>,
  'id'
>;

export interface EntityEventDocumentMethods {
  canRead(): Promise<boolean>;
  canEdit(user: UserDocument | Express.User | null | undefined): Promise<boolean>;
  canUpdateState(user: UserDocument | Express.User | null | undefined): Promise<boolean>;
  updateEntityEventState(
    state: EntityEventState,
    user: UserDocument | Express.User | null | undefined,
  ): Promise<void>;
}
