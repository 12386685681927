import type { PointWithOffset } from '../types/types';

const BIT_DEPTH = 7;
const BIT_LIMIT: number = 1 << BIT_DEPTH;
const BIT_MASK: number = BIT_LIMIT - 1;
const WORD_TO_HEX: Array<string> = [];

function populateWordToHex() {
  if (WORD_TO_HEX.length === 0) {
    for (let n = 0; n <= 0xffff; ++n) {
      const hexQuartet = n.toString(16).padStart(4, '0');
      WORD_TO_HEX.push(hexQuartet);
    }
  }
}

// populateWordToHex has to be called before using this function
function readMongoIdUltraFast(view: DataView, offset: number): string {
  let out = '';
  for (let i = offset; i < offset + 12; i += 2) {
    out += WORD_TO_HEX[view.getUint16(i)];
  }
  return out;
}

function decodeValuesFast(
  bytestream: DataView,
  offset: number,
  count: number,
  writer: (arg0: number, arg1: number) => void,
) {
  let i: number = offset;
  const len: number = bytestream.byteLength;

  let currentBytes = 0;
  let j = 0;

  for (; i < len && j < count; i++) {
    // Die Bytes werden in Form von druckbaren Zeichen übertragen. Der Wert des Bytes entspricht dem
    // Index des Zeichens in der Zeichenkette 'AVAILABLE_CHARS'. ('A' = 0, 'B' = 1, ...)
    // Die ersten Bytes, die zu einer zusammenhängenden Zahl gehören,
    // haben das Byte an Stelle 6 auf 1. Das letzte Byte einer Zahl hat
    // dieses Byte an Stelle 6 auf 0. Damit wird signalisiert, dass keine
    // weiteren Bytes zu der Zahl gehören. Wenn das Byte an Stelle 6 auf 1
    // gesetzt ist, ergibt a & 32 == 32.

    if ((bytestream.getInt8(i) & BIT_LIMIT) === BIT_LIMIT) {
      currentBytes++;
    } else {
      // Es wurde das letzte Byte einer Zahl erreicht. Die Zahl kann zusammengebaut werden.
      let value = 0;
      // Bytes werden von hinten nach vorn zusammengebaut.
      for (let k = 0; k <= currentBytes; k++) {
        // Bisherige Bits werden um 5 nach links verschoben.
        // Mit a & 31 werden die 5 letzten Bits ausgewählt.
        value = (value << BIT_DEPTH) + (bytestream.getInt8(i - k) & BIT_MASK);
      }

      // Die Zahl enthält immer ein Vorzeichenbit an Stelle 1.
      // Dieses muss aus der fertigen Zahl entfernt werden.
      // Dadurch werden alle Bits um 1 nach rechts verschoben.
      // Ist es auf 1 gesetzt muss die Zahl anschließend negativ gemacht werden.
      if ((value & 1) === 1) {
        value = ~(value >>> 1);
      } else {
        value = value >>> 1;
      }

      // Zahl ist fertig zusammengebaut und wird in Zwischenpuffer geschrieben.
      writer(j, value);
      j++;

      // Zurücksetzen der Variable.
      currentBytes = 0;
    }
  }
}

function decodePointArray(
  view: DataView,
  byteOffset: number,
  trackLength: number,
  stepWidth: number,
) {
  const array = new Array(trackLength);
  let lat = 0;
  let lng = 0;
  let time = 0;
  let offset = 0;

  decodeValuesFast(view, byteOffset, trackLength * 4, (i, v) => {
    const index = Math.floor(i / 4);
    let item: PointWithOffset | null = null;
    if (i % 4 === 0) {
      item = {
        lat: 0,
        lng: 0,
        time: 0,
        offset: 0,
      };
      array[index] = item;
    } else {
      item = array[index];
    }
    switch (i % 4) {
      case 0:
        // lat
        lat += v;
        item.lat = lat / 100000;
        break;
      case 1:
        // lng
        lng += v;
        item.lng = lng / 100000;
        break;
      case 2:
        // time
        time += v;
        item.time = time * stepWidth;
        break;
      case 3:
        // offset
        offset += v;
        item.offset = offset / 10;
        break;
      default:
        throw new Error('Invalid state');
    }
  });
  return array;
}

export function monotonicPush(a: Array<PointWithOffset>, b: Array<PointWithOffset>) {
  if (a.length === 0) {
    a.push(...b);
  } else {
    const aLastTime: number = a[a.length - 1].time;
    let bIndex = 0;
    for (; bIndex < b.length && b[bIndex].time <= aLastTime; bIndex++);
    a.push(...b.slice(bIndex));
  }
}

export function decodeBinary(
  buf: ArrayBuffer,
): Map<string, Array<Omit<PointWithOffset, 'time'> & { time: number }>> {
  populateWordToHex();
  const output = new Map();
  const view = new DataView(buf);

  if (view.byteLength === 0) return new Map();

  let offset = 0;
  const count: number = view.getUint32(offset, true);
  offset += 4;
  const stepWidth = view.getUint32(offset, true);
  offset += 4;
  for (let i = 0; i < count && offset < view.byteLength; i++) {
    const trackId = readMongoIdUltraFast(view, offset);
    offset += 12;
    const trackOffset = view.getUint32(offset, true);
    offset += 4;
    const trackLength = view.getUint32(offset, true);
    offset += 4;
    const array = decodePointArray(view, trackOffset, trackLength, stepWidth);
    output.set(trackId, array);
  }
  return output;
}

class BufferedWriter {
  static CHUNK_SIZE: number = 1024 * 32;
  chunks: Array<Uint8Array> = [];
  current: Array<number> = [];
  intBuffer: Uint32Array = new Uint32Array(1);
  intBufferView: Uint8Array = new Uint8Array(this.intBuffer.buffer);

  writeUint8(value: number) {
    this.current.push(value);
    if (this.current.length === BufferedWriter.CHUNK_SIZE) {
      this.chunks.push(new Uint8Array(this.current));
      this.current.length = 0;
    }
  }
  writeUint32(value: number) {
    this.intBuffer[0] = value;
    this.writeUint8(this.intBufferView[0]);
    this.writeUint8(this.intBufferView[1]);
    this.writeUint8(this.intBufferView[2]);
    this.writeUint8(this.intBufferView[3]);
  }
  get length() {
    return this.chunks.length * BufferedWriter.CHUNK_SIZE + this.current.length;
  }
  end() {
    const output: Uint8Array = new Uint8Array(this.length);
    for (let i = 0; i < this.chunks.length; i++) {
      output.set(this.chunks[i], i * BufferedWriter.CHUNK_SIZE);
    }
    output.set(new Uint8Array(this.current), this.chunks.length * BufferedWriter.CHUNK_SIZE);
    return output;
  }
}

function encodeNumber(num: number, writer: BufferedWriter) {
  // Source:
  // https://github.com/rcoup/py-gpolyencode/blob/master/cpp/GPolyEncoder.cpp
  let signedNum: number = num << 1;
  if (num < 0) {
    signedNum = ~signedNum;
  }
  while (signedNum >= BIT_LIMIT) {
    writer.writeUint8(BIT_LIMIT | (signedNum & BIT_MASK));
    signedNum = signedNum >> BIT_DEPTH;
  }
  writer.writeUint8(signedNum);
}

function encodeMongoId(mongoId: string, writer: BufferedWriter) {
  // Source: https://gist.github.com/xsleonard/7341172
  for (let i = 0; i < 12; i++) {
    writer.writeUint8(Number.parseInt(mongoId.substring(i * 2, i * 2 + 2), 16));
  }
}

function encodePointArray(
  points: Array<PointWithOffset>,
  stepWidth: number,
  writer: BufferedWriter,
) {
  const lastPoint: PointWithOffset = { lng: 0, lat: 0, time: 0, offset: 0 };
  for (const currentPoint of points) {
    const dLat: number = Math.round((currentPoint.lat - lastPoint.lat) * 100000);
    const dLng: number = Math.round((currentPoint.lng - lastPoint.lng) * 100000);
    const dTime: number = Math.round((currentPoint.time - lastPoint.time) / stepWidth);
    const dOffset: number = Math.round(currentPoint.offset - lastPoint.offset);

    encodeNumber(dLat, writer);
    encodeNumber(dLng, writer);
    encodeNumber(dTime, writer);
    encodeNumber(dOffset, writer);

    lastPoint.lat += dLat / 100000;
    lastPoint.lng += dLng / 100000;
    lastPoint.time += dTime * stepWidth;
    lastPoint.offset += dOffset;
  }
}

export function encodeBinary(
  trackMap: Map<string, Array<PointWithOffset>>,
  stepWidth: number,
): Uint8Array {
  const headerWriter: BufferedWriter = new BufferedWriter();
  headerWriter.writeUint32(trackMap.size);
  headerWriter.writeUint32(stepWidth);
  const payloadOffset: number = 8 + 20 * trackMap.size;
  const payloadWriter: BufferedWriter = new BufferedWriter();
  for (const [trackId, points] of trackMap.entries()) {
    encodeMongoId(trackId, headerWriter);
    headerWriter.writeUint32(payloadOffset + payloadWriter.length);
    headerWriter.writeUint32(points.length);
    encodePointArray(points, stepWidth, payloadWriter);
  }
  const headerBuffer: Uint8Array = headerWriter.end();
  const payloadBuffer: Uint8Array = payloadWriter.end();
  const output: Uint8Array = new Uint8Array(headerBuffer.byteLength + payloadBuffer.byteLength);
  output.set(headerBuffer);
  output.set(payloadBuffer, headerBuffer.length);
  return output;
}
